<template>
	<div class="pui-form-layout">
		<v-row dense class="mt-4">
			<pui-field-set :title="$t('form.mooring.serviceinfo')">
				<v-layout wrap>
					<v-flex xs12 md3>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`portname-${modelName}`"
									:attach="`portname-${modelName}`"
									:label="this.$t('form.towage.portname')"
									toplabel
									clearable
									required
									:disabled="!isCreatingElement || disableserviceInfo"
									v-model="parentObject"
									modelName="port"
									:itemsToSelect="itemsToSelectPort"
									:modelFormMapping="{ id: 'portid' }"
									:key="'portid_' + portidKey"
									itemValue="id"
									itemText="portname"
									reactive
									:fixedFilter="filterByUserPortsAndAuthority"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md3>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`statusname-${modelName}`"
									:attach="`statusname-${modelName}`"
									:label="this.$t('form.towage.statusname')"
									toplabel
									clearable
									:disabled="true"
									v-model="parentObject"
									modelName="technicalnauticalservicestatuspa"
									:itemsToSelect="[{ tnsstatuscode: this.parentObject.tnsstatuscode }]"
									:modelFormMapping="{ tnsstatuscode: 'tnsstatuscode' }"
									itemValue="tnsstatuscode"
									itemText="statusname"
									reactive
									:fixedFilter="filterByAuthority"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md6>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`visitid-${modelName}`"
									:attach="`visitid-${modelName}`"
									:label="$t('form.towage.portcallnumber')"
									toplabel
									clearable
									required
									:disabled="!parentObject.portid || !isCreatingElement || disableserviceInfo"
									v-model="parentObject"
									modelName="visit"
									:itemsToSelect="itemsToSelectVisits"
									:modelFormMapping="{ id: 'visitid' }"
									:itemValue="['id']"
									:itemText="
										(item) =>
											`${item.portcallnumber} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
												item.eta,
												'DD/MM/YYYY HH:mm'
											)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.etd, 'DD/MM/YYYY HH:mm')} - ${item.status}`
									"
									:order="{ eta: 'asc' }"
									:fixedFilter="portidFixedFilter"
									:key="'portid_' + portidKey"
									reactive
									detailComponentName="visit-form"
									detailModelName="visit"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md6 v-if="$store.getters.isMovementsActives">
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`movementid-${modelName}`"
									:attach="`movementid-${modelName}`"
									:label="$t('form.towage.movementnum')"
									toplabel
									clearable
									:disabled="!parentObject.visitid || !isCreatingElement"
									v-model="parentObject"
									modelName="movement"
									:itemsToSelect="itemsToSelectMovementNum"
									:modelFormMapping="{ id: 'movementid' }"
									itemValue="id"
									:itemText="(item) => getMovementText(item)"
									:order="{ nummovement: 'asc' }"
									:fixedFilter="movementVisitidFixedFilter"
									:key="'movementid_' + visitidKey"
									required
									reactive
									detailComponentName="movement-form"
									detailModelName="movement"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md6>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`stopid-${modelName}`"
									:attach="`stopid-${modelName}`"
									:label="$t('form.towage.stopnum')"
									toplabel
									clearable
									:disabled="!parentObject.visitid || !isCreatingElement"
									v-model="parentObject"
									modelName="stop"
									:itemsToSelect="itemsToSelectStops"
									:modelFormMapping="{ id: 'stopid' }"
									itemValue="id"
									:itemText="(item) => getStopItemText(item)"
									:order="{ num: 'asc' }"
									:fixedFilter="visitidFixedFilter"
									:key="'stopid_' + visitidKey"
									reactive
									detailComponentName="stop-form"
									detailModelName="stop"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-row>
		<v-row dense>
			<v-col cols="6">
				<pui-field-set :title="$t('form.towage.reqdate')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdatereq-towage`"
										v-model="parentObject.startdatereq"
										:label="$t('form.towage.startdatereq')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'RQ'"
										toplabel
										clearable
										time
										:max="parentObject.enddatereq"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddatereq-towage`"
										v-model="parentObject.enddatereq"
										:label="$t('form.towage.enddatereq')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'RQ'"
										toplabel
										clearable
										time
										:min="parentObject.startdatereq"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-col>
			<v-col cols="6" v-if="hasServiceStatusId('AU')">
				<pui-field-set :title="$t('form.towage.planneddate')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdateplanned-towage`"
										v-model="parentObject.startdateplanned"
										:label="$t('form.towage.startdateplanned')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'AU'"
										toplabel
										time
										:max="parentObject.enddateplanned"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddateplanned-towage`"
										v-model="parentObject.enddateplanned"
										:label="$t('form.towage.enddateplanned')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'AU'"
										toplabel
										time
										:min="parentObject.startdateplanned"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-col>
			<v-col cols="6" v-if="hasServiceStatusId('PL')">
				<pui-field-set :title="$t('form.towage.estimateddate')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdateestimated-towage`"
										v-model="parentObject.startdateestimated"
										:label="$t('form.towage.startdateestimated')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'PL'"
										toplabel
										time
										:max="parentObject.enddateestimated"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddateestimated-towage`"
										v-model="parentObject.enddateestimated"
										:label="$t('form.towage.enddateestimated')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'PL'"
										toplabel
										time
										:min="parentObject.startdateestimated"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="6">
				<pui-field-set :title="$t('form.towage.actualdate')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdateactual-towage`"
										v-model="parentObject.startdateactual"
										:label="$t('form.towage.startdateactual')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'IN'"
										toplabel
										time
										:max="parentObject.enddateactual"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddateactual-towage`"
										v-model="parentObject.enddateactual"
										:label="$t('form.towage.enddateactual')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'FI'"
										toplabel
										time
										:min="parentObject.startdateactual"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-col>
		</v-row>
		<v-row dense>
			<pui-field-set :title="$t('form.towage.maininfo')">
				<v-layout wrap>
					<v-flex xs12 md4>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									id="tugboatid"
									attach="tugboatid"
									v-model="parentObject"
									:label="$t('form.towage.tugboatid')"
									:disabled="formDisabled || disableForm"
									modelName="tugboat"
									:modelFormMapping="{ id: 'tugboatid' }"
									:itemsToSelect="[{ id: this.parentObject.tugboatid }]"
									itemValue="id"
									itemText="fullname"
									required
									toplabel
									reactive
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md4>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									id="maneuvertype"
									v-model="parentObject"
									:label="$t('form.towage.maneuvertype')"
									toplabel
									:disabled="formDisabled || disableForm"
									required
									modelName="maneuvertype"
									:itemsToSelect="[{ maneuvertypecode: this.parentObject.maneuvertype }]"
									:modelFormMapping="{ maneuvertypecode: 'maneuvertype' }"
									itemValue="maneuvertypecode"
									itemText="description"
									reactive
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md4 v-if="!isCreatingElement && parentObject.tnsstatuscode === 'AN'">
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`cancelationreason-${modelName}`"
									:attach="`cancelationreason-${modelName}`"
									v-model="parentObject"
									:label="$t('form.towage.cancelationreason')"
									toplabel
									clearable
									disabled
									modelName="cancelationreason"
									:itemsToSelect="[{ id: parentObject.cancelationreasonid }]"
									:modelFormMapping="{ id: 'cancelationreasonid' }"
									itemValue="id"
									itemText="description"
									reactive
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md12>
						<v-layout wrap>
							<v-flex xs12>
								<pui-text-area
									:id="`comments-towage`"
									v-model="parentObject.comments"
									:label="$t('form.towage.comments')"
									:disabled="formDisabled || disableForm"
									toplabel
									maxlength="300"
								></pui-text-area>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'towage-form-main-tab',
	data() {
		return {
			portidKey: 0,
			visitidKey: 0,
			visitidFixedFilter: null,
			movementVisitidFixedFilter: null,
			visiteta: null,
			visitetd: null,
			statusAp: [],
			disableForm: null,
			disableserviceInfo: false
		};
	},
	props: {
		modelName: {
			type: String,
			required: true
		},
		parentObject: {
			type: Object,
			required: true
		},
		modelPk: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(startdate, 'DD/MM/YYYY HH:mm')}  ${
				enddate ? '-' + this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm') : ''
			} - ${item.status} - ${item.location}`;
		},
		updateValidations(visitid) {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.parentObject.visitid ? this.parentObject.visitid : visitid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0]) {
					this.visiteta = response.data.data[0].eta;
					this.visitetd = response.data.data[0].etd;
				}
			});
		},
		hasServiceStatusId(tnsstatuscode) {
			return this.statusAp.includes(tnsstatuscode);
		},
		getMovementText(item) {
			return `${item.nummovement} - ${item.movementtype}  ${
				item.starttime ? '-' + this.$dateTimeUtils.getLocalFormattedDate(item.starttime, 'DD/MM/YYYY HH:mm') : ''
			}  ${item.endtime ? '-' + this.$dateTimeUtils.getLocalFormattedDate(item.endtime, 'DD/MM/YYYY HH:mm') : ''}
											- ${item.movementstatus}`;
		}
	},
	computed: {
		itemsToSelectPort() {
			return [{ id: this.parentObject.portid }];
		},
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			const userPortAuthority = window.localStorage.getItem('workingPortAuthorityId');
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: userPortAuthority }
				]
			};
		},
		filterByAuthority() {
			const userPortAuthority = window.localStorage.getItem('workingPortAuthorityId');
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portauthorityid', op: 'eq', data: userPortAuthority }]
			};
		},
		itemsToSelectVisits() {
			return [{ id: this.parentObject.visitid }];
		},
		itemsToSelectStops() {
			return [{ id: this.parentObject.stopid }];
		},
		itemsToSelectMovementNum() {
			return [{ id: this.parentObject.movementid }];
		},
		visitStatusCodes() {
			return this.$store.getters.getActiveVisitStatusid;
		},
		movementStatusCodes() {
			return this.$store.getters.getActiveMovementStatusid;
		},
		portidFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portid', op: 'eq', data: this.parentObject.portid },
					{ field: 'statusid', op: 'in', data: this.visitStatusCodes }
				]
			};
		}
	},
	created() {
		this.$puiEvents.$on(`onPuiSelect_selectedItems-movementid-${this.modelName}`, (newVal) => {
			if (newVal.model) {
				this.parentObject.stopid = newVal.model.stopto;
			}
		});
	},
	watch: {
		'parentObject.portid'(newVal) {
			if (!newVal) {
				this.visitidFixedFilter = null;
				this.movementVisitidFixedFilter = null;
				this.parentObject.portid = null;
				this.parentObject.stopid = null;
				this.parentObject.movementid = null;
			}
			this.portidKey += 1;
		},
		'parentObject.visitid'(newVal) {
			if (newVal) {
				this.visitidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'visitid', op: 'eq', data: newVal }]
				};
				this.movementVisitidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'visitid', op: 'eq', data: newVal },
						{ field: 'movementstatuscode', op: 'in', data: this.movementStatusCodes }
					]
				};
			} else {
				this.visitidFixedFilter = null;
				this.movementVisitidFixedFilter = null;
				this.parentObject.stopid = null;
			}
			this.visitidKey += 1;
			this.updateValidations();
		}
	},

	mounted() {
		if (!this.isCreatingElement) {
			//Get towage data stop
			const opts = {
				model: 'towage',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'service_id',
							op: 'eq',
							data: this.modelPk.serviceid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0].locationid) {
					//Get towage data visit
					this.updateValidations(response.data.data[0].visitid);
				}
			});

			if (this.parentObject.tnsstatuscode) {
				this.disableForm = this.parentObject.tnsstatuscode === 'AN' ? true : false;
			}
		} else {
			this.parentObject.tnsstatuscode = 'RQ';
			if (this.$route.params.visitpk) {
				const currentPath = this.$router.currentRoute.path.split('/');
				this.parentModelName = currentPath[1];
				if (this.parentModelName.includes('visit')) {
					this.disableserviceInfo = true;
					this.parentObject.visitid = JSON.parse(atob(this.$route.params.visitpk)).id;
					const opts = {
						model: 'visit',
						filter: {
							groupOp: 'and',
							groups: [],
							rules: [
								{
									field: 'id',
									op: 'eq',
									data: this.parentObject.visitid
								}
							]
						}
					};
					this.$puiRequests.postRequest('/puisearch', opts, (response) => {
						if (response.data.data[0]) {
							this.parentObject.portid = response.data.data[0].portid;
						}
					});
				}
			}
		}

		const opts2 = {
			model: 'technicalnauticalservicestatuspa',
			filter: {
				groupOp: 'and',
				groups: [],
				rules: [
					{
						field: 'port_authority_id',
						op: 'eq',
						data: window.localStorage.getItem('workingPortAuthorityId')
					}
				]
			}
		};
		this.$puiRequests.postRequest('/puisearch', opts2, (response) => {
			if (response.data) {
				//Get towage data visit
				this.statusAp = response.data.data.map((sap) => {
					return sap.tnsstatuscode;
				});
			}
		});
	},
	destroyed() {
		this.$puiEvents.$off(`onPuiSelect_selectedItems-movementid-${this.modelName}`);
	}
};
</script>
